import { connect } from 'react-redux'
import CartBudgetComponent from "../components/CartBudgetComponent"

const mapStateToProps = ({ display, userAccount }) => ({
  cart: display.cart,
  currency: userAccount.currency,
  currentCampaign: display.currentCampaign,
  editCampaignActive: display.editCampaignActive,
  realAmount: display.realAmount,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
})

export default connect(mapStateToProps)(CartBudgetComponent)
