import React, { useEffect, useState } from 'react';
import { CircleFlag } from "react-circle-flags";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ScreensGroupContainer from "../containers/ScreensGroupContainer";
import ScreensCollectionHolder from "./Styles/screensCollectionStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import NumberFormat from "./NumberFormat";
import {calculeValuesPerGroup} from "../services/utils";

const { Title } = Typography;

const ScreensCollectionComponent = (props) => {
  const { t } = useTranslation();

  const [displaysCollections, setDisplaysCollections] = useState([]);

  const {
    cart,
    managerDisplaySelector,
    disabledManagerDisplayFilter,
    applyRelationOnScreens ,
    allDisplaysAreOwn,
    creditAvailableOnTransferredImpressions,
    updateCreditAvailable,
    programmatic,
    customContents,
    currency,
    audience,
    isPaymentStatusApproved
  } = props;

  const getFlag = (collection) => {
    const firstDisplay = collection.displays[0];
    const countryAbbreviation = firstDisplay && firstDisplay.country_iso && firstDisplay.country_iso.toLowerCase();

    return countryAbbreviation;
  }

  const groupByCountry = () => {
    return Object.values(cart.reduce((acc, display) => {
      const country = display.country || "Not assigned";

      if (!acc[country]) {
        acc[country] = { key: country, displays: [] };
      }

      acc[country].displays.push(display);
      return acc;
    }, {}));
  };

  const groupByCompany = () => {
    let result =  Object.values(cart.reduce((acc, display) => {
      const company_id = display.company_id || "Not assigned";

      if (!acc[company_id]) {
        acc[company_id] = { key: display.company.name, displays: []};
      }
      acc[company_id].displays.push(display);

      return acc;
    }, {}));

    if (programmatic === "programmatic") {
      result = result.map((group) => {
        let investmentGroup
        [ investmentGroup ] = calculeValuesPerGroup(group, customContents, audience, { calculateImpacts: false });
        return { ...group, investmentGroup };
      });
    }

    return result;
  };

  const groupAccordingToFilter = () => {
    let displaysGroupAux;

    if (managerDisplaySelector === "country") displaysGroupAux = groupByCountry();
    if (managerDisplaySelector === "company") displaysGroupAux = groupByCompany();

    setDisplaysCollections(displaysGroupAux);
  };

  useEffect(() => {
    if (!managerDisplaySelector) return;

    groupAccordingToFilter();
  }, [cart, managerDisplaySelector]);

  useEffect(() => {
    if (managerDisplaySelector === "company") groupAccordingToFilter();
  }, [customContents]);

  return (
    <ScreensCollectionHolder>
      {managerDisplaySelector && !disabledManagerDisplayFilter ? (
        <Row type="flex" className="Screens-collection">
          {displaysCollections.map((collection, index) => (
            <Row key={index} className="collection" type="flex">
              <Row
                type="flex"
                className="title-collection appear-animation-without-movement"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title level={3} className="margin-text">{t(collection.key)}</Title>
                  {managerDisplaySelector === "country" ? (
                    <CircleFlag className="margin-text" countryCode={getFlag(collection)} height="24" width="24" />
                  ) : (
                    <FontAwesomeIcon className="iconCompany" icon={faBuilding} style={{ fontSize: "20px" }} />
                  )}
                  <Title level={3} className="margin-text">
                    {`(${collection.displays.length || 0})`}
                  </Title>
                </div>
                { (programmatic === "programmatic" && managerDisplaySelector === "company") && (
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className='textGroup margin-text'>{t('Investment')}</span>
                    <span className="amountCampaignPerDisplay" style={{ marginLeft: 3 }}>
                      <NumberFormat
                        tax_include={false}
                        value={collection.investmentGroup}
                        currency={currency}
                      />
                    </span>
                  </div>
                )}
              </Row>
              <ScreensGroupContainer
                displaysCollection={collection.displays}
                applyRelationOnScreens={applyRelationOnScreens}
                allDisplaysAreOwn={allDisplaysAreOwn}
                creditAvailableOnTransferredImpressions={creditAvailableOnTransferredImpressions}
                updateCreditAvailable={updateCreditAvailable}
                isPaymentStatusApproved={isPaymentStatusApproved}
              />
            </Row>
          ))}
        </Row>
      ) : (
        <ScreensGroupContainer
          displaysCollection={cart}
          applyRelationOnScreens={applyRelationOnScreens}
          allDisplaysAreOwn = {allDisplaysAreOwn}
          isPaymentStatusApproved={isPaymentStatusApproved}
          creditAvailableOnTransferredImpressions={creditAvailableOnTransferredImpressions}
          updateCreditAvailable = {updateCreditAvailable}
        />
      )}
    </ScreensCollectionHolder>
  );
};

export default ScreensCollectionComponent;