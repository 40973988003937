import { connect } from 'react-redux'
import AdScreenCard from '../components/AdScreenCard'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  token: userAccount.token,
  loadingSingleReport: display.loadingSingleReport,
  reportSingleData: display.reportSingleData,
  loadingChangeSpot: display.loadingChangeSpot,
  loadingChangeSpotSuccess: display.loadingChangeSpotSuccess,
  newSpotsChanged: display.newSpotsChanged,
  errors: display.errors,
  user: userAccount.user,
  audienceDatsWhy: display.audienceDatsWhy,
  loadingAudienceDatsWhy: display.loadingAudienceDatsWhy,
  invoiceIssuingCountry: display.invoiceIssuingCountry,
})

const mapStateToDispatch = (dispatch) => ({
  updateContentDisplayByContent: (content, displayId) => dispatch(DisplayActions.updateContentDisplayByContent(content, displayId)),
  cleanUpdateSpots: () => dispatch(DisplayActions.cleanUpdateSpots()),
  getReportRequest: (reportData) => dispatch(DisplayActions.getReportRequest(reportData)),
  getSingleReportRequest: (reportSingleData) => dispatch(DisplayActions.getSingleReportRequest(reportSingleData)),
  updateContentCampaignRequest: (content, relation, campaignId) => dispatch(DisplayActions.updateContentCampaignRequest(content, relation, campaignId)),
  updateContentCampaignSuccess: (content, relation) => dispatch(DisplayActions.updateContentCampaignSuccess(content, relation)),
  updateContentCampaignFailure: (errors) => dispatch(DisplayActions.updateContentCampaignSuccess(errors)),
  changeStateUpdateContent: (stateLoading) => dispatch(DisplayActions.changeStateUpdateContent(stateLoading)),
  getAudienceDatsWhyRequest: (id) => dispatch(DisplayActions.getAudienceDatsWhyRequest(id)),
  setAudienceDatsWhy: (data) => dispatch(DisplayActions.setAudienceDatsWhy(data)),
})

export default connect(mapStateToProps, mapStateToDispatch)(AdScreenCard)
