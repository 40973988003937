import { connect } from 'react-redux'
import CartComponent from '../components/CartComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  date_from: display.date_from,
  date_to: display.date_to,
  cart: display.cart,
  currencyExchangeRate: display.currencyExchangeRate,
  creatingQuote: display.creatingQuote,
  quote: display.quote,
  errors: display.errors,
  client: display.client,
  brand: display.brand,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  audience: display.audience,
  loadingAudience: display.loadingAudience,
  loadingExcel:display.loadingExcel,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  currency: userAccount.currency,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  dsp: display.dsp,
  newVersionCampaign: display.newVersionCampaign,
  isSuperUser: userAccount.isSuperUser,
})

const mapStateToDispatch = (dispatch) => ({
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  createQuote: (quoteData) => dispatch(DisplayActions.createQuoteRequest(quoteData)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  getExcelDisplays: (data) => dispatch(DisplayActions.getExcelDisplaysRequest(data)),
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
  getCurrencyExchangeRate: () => dispatch(DisplayActions.getCurrencyExchangeRateRequest()),
  setNewVersionCampaign: (newVersionCampaign) => dispatch(DisplayActions.setNewVersionCampaign(newVersionCampaign)),
})

export default connect(mapStateToProps, mapStateToDispatch)(CartComponent)
