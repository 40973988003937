import styled from "styled-components";
import Colors from "../../themes/Colors";

const ContentsOfCampaignComponent = styled.div`
  .cardContent {
    padding: 12px 12px 8px 12px;
    gap: 7px;
    border-radius: 4px;
    width: min-content;
    min-height: 200px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .icon-card {
    width: 26px;
    height: 32px;
  }
  .icon-card svg {
    font-size: 16px;
  }
  .contentCardContent {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
  }
  .textCard {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000;
    margin-bottom: 0px;
  }
  .contentTitleRelation {
    display: flex;
    justify-content: space-between;
    width: 130px;  /* Define de width of the card */
    margin-bottom: 10px;
  }
  .required-resolution .slick-track{
    display: flex;
    width: unset !important;
    .slick-slide {
      margin-bottom: 10px;
    }
  }
  .contentRelation > .slick-track{
    min-height: 220px;
  }
  .contentRelation > .ant-card-bordered {
    border: 0px
  }
  .skeletonRequiredCard .ant-skeleton-title {
    display: none;
  }
  .skeletonRequiredCard .ant-skeleton-paragraph {
    margin: 0px !important;
  }
  .skeletonRequiredCard .ant-skeleton-paragraph li {
    height: 188px;
  }

  .inputVersion {
    marginBottom: 5px;
    width: 150px; /* Define de width of the card */
    font-size: 13px;
  }
  .alignImage {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color: black;
  }
  .contentButtonCardResolution {
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
  }
  .contentCardImage {
    width: 115px;
    height: 70px;
    border-radius: 2px;
    object-fit: scale-down;
  }
  .contentRectangle{
    width: 134px;
    height: 70px;
    display: flex;
    justify-content: center;
  }
  .contentRectangleCardImage {
    width: 100%;
    object-fit: scale-down;
  }
  .contentCollectionCard {
    margin: 10px;
  }

  .centerCard {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardRed {
    background: #FDEAEE;
  }
  .cardRed svg {
    color: #CD2749;
  }
  .inputContentName {
    margin-bottom: 15px;
    width: 105px;
    font-size: 13px;
    padding: 5px;
    margin-right: 5px;
  }
  .primaryCircle {
    width: 24px;
    height: 24px;
    background-color: ${Colors.primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    margin-bottom: 4px;
  }
  .inputContentName::placeholder {
      font-size: 11px;
  }
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-color: #d9d9d957;
    color: #000000;
    margin-bottom: 4px;
  }
  .greenCircle {
    width: 24px;
    height: 24px;
    background-color: #7CB354;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    margin-bottom: 4px;
  }
  .slick-slide {
    padding-right: 5px;
    padding-left: 5px;
    width: auto !important;
    min-height: 200px;
  }
  .slick-next:before {
    color: #0c0b0b8c;
    font-size: 28px !important;
  }
  .slick-prev:before {
    color: #0c0b0b8c;
    font-size: 28px !important;
  }
  .slider-container {
    margin-left: 25px;
    margin-right: 20px;
  }
  .contentMessage {
    background: #FAFAFA;
    border: 1px dashed #C3C3C3;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .contentMessage p {
    margin-bottom: 0px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    color: #888888;
  }
  .buttonContent {
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .contentTolerance {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-items: baseline;
    p {
      margin-bottom: 0;
      color: ${Colors.primary};
    }
  }
  .cardNoContent {
    border-radius: 2px;
    display: flex;
    width: 115px;
    align-items: center;
    justify-content: center;
    background: #d9d9d957;
  }
  .rectangleNoContent{
    border: 1px dashed #00000073;
    background: transparent;
  }
  .card-wrapper.multi {
    position: absolute;
    transition: transform 0.4s ease, z-index 0.3s ease;
    top: -15px;
  }
  .card-wrapper.multi:hover {
    margin-top: 4.5px;
    z-index: 99 !important;
    box-shadow: 4px 0 6px rgba(0, 0, 0, 0.1), -4px 0 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
  }
  .error-message-contents-displays {
    margin-bottom: 5px;
  }
  .border-orange{
    border: 2px solid #FAAD14;
  }
  .ant-collapse-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export default ContentsOfCampaignComponent;