import React, { useEffect, useState} from 'react';
import BundleCardHolder from './Styles/bundleCardStyle'
import { Card, Button, Icon, Row, Col, List, Typography} from "antd";
import { getIconType , truncateText} from "../services/display"
import { useTranslation } from 'react-i18next';
import { BundleSvg } from "../images/customIcons/BundleSvg";
import NumberFormat from './NumberFormat';
import moment from "moment";

const BundleCardComponent = (props) => {
  const { t } = useTranslation();
  // popup: Define if the component is used in a popup or in a view when not logged in.
  const { bundleData , programmatic, currency, popup, getDisplaysCart, DisplayMap , editCart, listBundle, fetching, closeModal, cart} = props;
  const [ loadingAddBundle, setloadingAddBundle] = useState(false);
  const [ loadingDeleteBundle, setloadingDeleteBundle] = useState(false);

  // Define the list of displays depending on the type of campaign being created.
  var listDisplaysOfBundles = bundleData.displays;
  if (programmatic == "programmatic") {
    listDisplaysOfBundles = listDisplaysOfBundles.filter((display) => {
      return display.smart_campaign_enabled;
    });
  }

  if (programmatic=="traditional"){
    listDisplaysOfBundles = listDisplaysOfBundles.filter((display) => {
      return display.published;
    });
  }

  // List diferent types of displays
  const searchTypeBunlde = (listDisplays) => {
    let typeDisplays = "";
    const uniqueTypes = new Set();

    listDisplays.forEach((display, key) => {
      if (display.location_type && !uniqueTypes.has(display.location_type)) {
        if (typeDisplays.length > 0) {
          typeDisplays += " + ";
        }
        typeDisplays += display.location_type;
        uniqueTypes.add(display.location_type);
      }
    });
    return typeDisplays;
  };

  const addDisplaysInCart =  (list_display, bundle_id) => {
      const { date_from, date_to, invoiceIssuingCountry, client, dsp , listBundle } = props;
      let list_id = [...new Set(cart.map((item) => item.id))];
      list_display = list_display.map((item) => item.id)
      list_id = new Set([...list_id, ...list_display]);
      let newListBundle = [...listBundle, bundle_id];

      getDisplaysCart({
          date_from: moment(date_from || new Date()).format("YYYY-MM-DD"),
          date_to: moment(date_to || new Date()).format("YYYY-MM-DD"),
          currency: currency,
          invoice_issuing_country: invoiceIssuingCountry,
          client_id: client ? client.id : null,
          list_id: [...list_id],
          external_dsp_id: dsp ? dsp.id : null,
          smart_campaign_enabled: programmatic === "programmatic" ? 1 : null,
          listBundle: newListBundle
      });
      setloadingAddBundle(true);
  }

  const deleteDisplaysInCart = (bundle_id) => {
    const { date_from, date_to, invoiceIssuingCountry, client, dsp , cart, listBundle} = props;
    let list_id_cart = [];
    // Delete bundle_id of listBundle
    let newListBundle = listBundle.filter((itemlistBundle) => itemlistBundle !== bundle_id);
    cart.forEach((item) => {
      // check if belongs to bundles, if not add to list
      if( item.bundles && item.bundles.length < 1) {
        list_id_cart.push(item.id)
        return;
      }

      // check if belongs to other bundles, if not add to list
      if(item.bundles.filter((bundle) => bundle.id === bundle_id).length < 1) {
        list_id_cart.push(item.id)
      } else {
        // check if campaign have bundles
        if(newListBundle.length < 1) {
          // Id display not have bundle associated add to cart
          if(item.bundles && item.bundles.length < 0) list_id_cart.push(item.id)

          // If not have bundles associated, is the bundle to delete, not add to cart
          return
        }
        // check display have other bundles associated, so not delete of cart
        if (item.bundles.filter((bundles) => newListBundle.some((bunldeId) => bundles.id === bunldeId)).length > 0) {
          list_id_cart.push(item.id)
        }
      }
    })
    setloadingDeleteBundle(true);
    // let newListBundle = listBundle.filter((item) => item !== bundle_id);
    // If not have displays in cart, clean cart and not call api
    if (list_id_cart.length === 0) {
        cleanCart();
    } else {
      getDisplaysCart({
          date_from: moment(date_from || new Date()).format("YYYY-MM-DD"),
          date_to: moment(date_to || new Date()).format("YYYY-MM-DD"),
          currency: currency,
          invoice_issuing_country: invoiceIssuingCountry,
          client_id: client ? client.id : null,
          list_id: [...list_id_cart],
          external_dsp_id: dsp ? dsp.id : null,
          smart_campaign_enabled: programmatic === "programmatic" ? 1 : null,
          listBundle: newListBundle,
      });
    }
  }

  const cleanCart = () => {
    const { cleanAudience, removeDisplaysCart } = props;
    cleanAudience();
    removeDisplaysCart();
  }

  /* cart: When add or delete display with bundle,
     your add displays in cart, so wait for the cart to update */
  useEffect(() => {
    if(loadingAddBundle){
      closeModal();
      setloadingAddBundle(false);
    }
    if(loadingDeleteBundle){
      closeModal();
      setloadingDeleteBundle(false);
    }
  } ,[cart]);

  return (
    <BundleCardHolder>
      <Card className="screenCard">
        <div className="titleBundle screenCardContent">
            <span className='titleBundle screenCardContent bundleIcon'>
              <Icon component={BundleSvg} style={{marginRight: '10px', width: '26px'}}/>
              <h2>{bundleData.name}</h2>
            </span>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={9}>
                <Card className="screenCard content-map-bunlde">
                  <DisplayMap
                    display={listDisplaysOfBundles}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div
                        className="mapHeight"
                        style={{ height: `180px`, borderRadius: "10px" }}
                      />
                    }
                    mapElement={
                      <div style={{ height: `100%`, borderRadius: "10px" }} />
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={7}>
               <div style={{paddingLeft: "10px"}}>
                  <div className='title-displays title-col'><b>{t("Displays of bundle")}</b></div>
                  <List
                    bordered = {false}
                    style={{ marginRight: "20px", maxHeight: "220px", overflowY: "auto" }}
                    className='listDisplays contenedor-list-displays'
                    dataSource={listDisplaysOfBundles}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Typography.Text mark>
                          <img
                            src={getIconType(item.location_type, item.display_type, false, "isNotPin")}
                            className="imagenTypeScreen"
                          />
                        </Typography.Text> {truncateText(item.name, 25)} 
                        <a
                          className="link"
                          style={{ marginLeft: "5px" }}
                          href={`/screen/${item.id}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Icon type="link" />
                          </a>
                      </List.Item>
                    )}
                  />
              </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <div className='content-info-bunlde'>
                  <div className='title-col'>
                    <b>{t('Name')}:</b>
                    <span className="primary">{bundleData.name}</span>
                  </div>
                  <div className='title-col'>
                    <b>{t('Number of screens')}:</b>
                    <span className="primary">{listDisplaysOfBundles.length} {t('screens')}</span>
                  </div>
                  <div className='title-col'>
                    <b>{t('Type of bundle')}:</b>
                    <span className="primary">{searchTypeBunlde(listDisplaysOfBundles)}</span>
                  </div>
                  {/* Displays prices according to the campaign being created, or if viewed without logging in, it shows both. */}
                  {programmatic === "traditional" || !programmatic ? (
                    <div className='title-col'>
                      <b>{t('Traditional price')}:</b>
                      <span className="primary">
                      <NumberFormat
                        value={bundleData.price_per_month}
                        currency={currency}
                      />/{t('month')}</span>
                    </div>): null
                  }
                  {programmatic === "programmatic" || !programmatic ? (
                    <>
                      <div className='title-col'>
                        <b>{t('CPM min')}:</b>
                        <span className="primary">
                          <NumberFormat
                            value={bundleData.min_cpm}
                            currency={currency}/>
                        </span>
                      </div>
                      <div className='title-col'>
                        <b>{t('CPM max')}:</b>
                        <span className="primary">
                          <NumberFormat
                            value={bundleData.max_cpm}
                            currency={currency}/>
                        </span>
                      </div>
                    </>
                  ): null}
                  {/* ******** */}
                    {popup && editCart && (
                      (listBundle.includes(bundleData.id) && !loadingAddBundle) || loadingDeleteBundle ? (
                        loadingDeleteBundle ? (
                          <Button style={{marginTop: "10px", marginBottom: "10px"}} loading> {t('Delete to cart')}</Button>
                        ) : (
                          <Button onClick={() => deleteDisplaysInCart(bundleData.id)} style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Icon className="icon" type="shopping-cart" /> {t('Delete to cart')}
                          </Button>
                        )
                      ) : (
                        loadingAddBundle ? (
                          <Button style={{marginTop: "10px", marginBottom: "10px"}} loading> {t('Add to cart')}</Button>
                        ) : (
                          <Button onClick={() => addDisplaysInCart(Object.values(listDisplaysOfBundles), bundleData.id)} style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Icon className="icon" type="shopping-cart" /> {t('Add to cart')}
                          </Button>
                        )
                      )
                    )}
                </div>
              </Col>
            </Row>
        </div>
      </Card>
    </BundleCardHolder>
  );
};

export default BundleCardComponent;